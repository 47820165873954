import { UpperCasePipe } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { DomSanitizer } from '@angular/platform-browser'
import { ActivatedRoute, ParamMap, Router, RouterLink } from '@angular/router'
import { TranslocoService, TranslocoModule } from '@ngneat/transloco'
import { of, Subscription, throwError } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { IconOutlined } from 'src/app/common/icons'
import { ButtonComponent } from 'src/app/common/ui/button/button.component'
import { CardComponent } from 'src/app/common/ui/card/card.component'
import { TextComponent } from 'src/app/common/ui/text/text.component'

import { UserService } from '~core/services'
import { FacebookApiService } from '~core/services/facebook-api.service'
import { Helper } from '~core/services/helper.service'
import { SnackbarService } from '~core/services/ui/snackbar.service'
import { truthy } from '~core/utils/operators'
import { User } from '~features/user/models/user.model'

import { InfoSectionPromoComponent } from '../../components/info-section/info-section-promo.component'
import { InfoSectionComponent } from '../../components/info-section/info-section.component'

@Component({
  selector: 'app-signup',
  templateUrl: './signup.view.html',
  standalone: true,
  imports: [
    TranslocoModule,
    InfoSectionComponent,
    InfoSectionPromoComponent,
    CardComponent,
    TextComponent,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    MatFormFieldModule,
    MatInputModule,
    RouterLink,
    UpperCasePipe,
  ],
})
export class SignupView implements OnInit, OnDestroy {
  form: FormGroup
  isLoading = false
  isMarketingEspressoPromo = false
  isPasswordFocused = false
  showPassword: boolean

  private coupon: string
  private facebookAuthCode: string
  private facebookLoginSubscription = Subscription.EMPTY
  private facebookRedirectUri: string
  private routeSubscription = Subscription.EMPTY
  protected Icons = IconOutlined

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private facebookService: FacebookApiService,
    private translateService: TranslocoService,
    private helper: Helper,
    private sanitizer: DomSanitizer,
    private snackbarService: SnackbarService,
  ) {}

  initForm(): void {
    this.form = new FormGroup({
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [
        Validators.required,
        Validators.minLength(8), // must be at least 8 characters
        Validators.pattern(/[A-Z]/), // must contain at least one lowercase
        Validators.pattern(/[a-z]/), // must contain at least one uppercase
      ]),
    })
  }

  initRouteSubscription(): void {
    this.routeSubscription = this.route.paramMap.subscribe((params: ParamMap) => {
      // If the url has coupon param, then the user is registering with one time offer coupon
      if (params.has('coupon') && params.get('coupon') === 'marketing-espresso-15') {
        this.coupon = params.get('coupon')
        this.isMarketingEspressoPromo = true
      }
    })
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe()
  }

  ngOnInit(): void {
    this.facebookRedirectUri = window.location.origin + window.location.pathname
    this.initRouteSubscription()
    this.subscribeToFacebookLogin()
    this.initForm()
  }

  onSignUp(): void {
    if (!this.form.valid) {
      this.helper.touchAllFormFields(this.form)
      return
    }

    this.isLoading = true

    const user = new User()
    const formValues = this.form.value
    user.firstName = formValues.firstName
    user.lastName = formValues.lastName
    user.email = formValues.email
    user.password = formValues.password

    this.userService
      .create(user)
      .pipe(
        switchMap(this.handleCoupon),
        catchError((err) => {
          this.isLoading = false
          return throwError(err)
        }),
      )
      .subscribe(() => this.router.navigateByUrl('/'))
  }

  onSignupViaFacebook(): void {
    this.isLoading = true
    this.facebookService.login(this.facebookRedirectUri)
  }

  subscribeToFacebookLogin(): void {
    this.facebookLoginSubscription = this.route.queryParamMap
      .pipe(
        switchMap((queryParams: ParamMap) => {
          if (!queryParams.has('granted_scopes') || !queryParams.has('code')) {
            this.removeQueryParams()
            return of(false)
          }
          this.isLoading = true
          const grantedScopes = queryParams.get('granted_scopes').split(',')
          this.facebookService.checkScopes(['email'], grantedScopes)
          this.facebookAuthCode = queryParams.get('code')
          return this.userService.facebookRegister(this.facebookAuthCode, this.facebookRedirectUri)
        }),
        truthy(),
        switchMap(this.handleCoupon),
        catchError((error) => {
          if (error instanceof Error) {
            this.snackbarService.error(error.message)
          }
          this.isLoading = false
          this.removeQueryParams()
          return throwError(error)
        }),
      )
      .subscribe(() => {
        this.removeQueryParams()
        this.redirectAfterSignup()
      })
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword
  }

  private handleCoupon = (user: User) => {
    if (this.coupon && this.isMarketingEspressoPromo) {
      return this.userService.redeemCoupon(this.coupon)
    }
    return of(user)
  }

  private redirectAfterSignup() {
    this.router.navigate(['/projects/new'])
  }

  private removeQueryParams(): void {
    this.router.navigate([this.router.url.split('?')[0]])
  }
}
