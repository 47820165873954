import { ApplicationConfig } from '@angular/core'
import { provideTranslocoScope } from '@ngneat/transloco'

import { commonConfig } from '~common/common.config'
import { translocoScopeLoader } from '~core/utils'

export const projectsConfig: ApplicationConfig['providers'] = [
  provideTranslocoScope({
    scope: 'projects',
    loader: translocoScopeLoader((lang: string, root: string) => import(`./${root}/${lang}.json`)),
  }),
  ...commonConfig,
]
