import { AsyncPipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { IconOutlined } from 'src/app/common/icons'
import { AppLayoutComponent } from 'src/app/common/layout/app-layout/app-layout.component'
import { SidebarItem } from 'src/app/common/layout/sidebar/sidebar.component'
import { SidebarComponent } from 'src/app/common/layout/sidebar/sidebar.component'

import { selectIsMobileSidebar, selectSidebarExpanded } from '~core/store/ui/ui.selectors'

@Component({
  selector: 'app-admin',
  template: `
    <sb-app-layout>
      <ng-template #sidenav>
        <sb-sidebar
          [items]="sidebarItems"
          [expanded]="expanded$ | async"
          [isMobileSidebar]="isMobileSidebar$ | async"
        />
      </ng-template>
      <router-outlet></router-outlet>
    </sb-app-layout>
  `,
  standalone: true,
  imports: [AppLayoutComponent, SidebarComponent, RouterOutlet, AsyncPipe],
})
export class AdminRouter implements OnInit {
  expanded$: Observable<boolean>
  isMobileSidebar$: Observable<boolean>
  sidebarItems: SidebarItem[] = [
    {
      label: 'Support',
      icon: IconOutlined.SupportAgent,
      path: '/admin/support',
    },
    {
      label: 'Sentiment review',
      icon: IconOutlined.Sentiment,
      path: '/admin/sentiment-review',
    },
    {
      label: 'Design Library',
      icon: IconOutlined.DesignServices,
      path: '/admin/design-library',
    },
    {
      label: 'Mautic',
      icon: IconOutlined.Mail,
      path: '/admin/mautic',
    },
  ]

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.expanded$ = this.store.select(selectSidebarExpanded)
    this.isMobileSidebar$ = this.store.select(selectIsMobileSidebar)
  }
}
