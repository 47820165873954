import { NgIf } from '@angular/common'
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { SafeHtml } from '@angular/platform-browser'
import { ActivatedRoute, ParamMap, Router, RouterLink } from '@angular/router'
import { TranslocoService, TranslocoModule } from '@ngneat/transloco'
import { of, Subscription } from 'rxjs'
import { filter, switchMap } from 'rxjs/operators'
import { LogoComponent } from 'src/app/common/components/logo/logo.component'
import { IconOutlined } from 'src/app/common/icons'
import { ButtonComponent } from 'src/app/common/ui/button/button.component'
import { DialogComponent } from 'src/app/common/ui/dialog/dialog.component'
import { TextComponent } from 'src/app/common/ui/text/text.component'

import { UserService } from '~core/services'
import { FacebookApiService } from '~core/services/facebook-api.service'
import { DialogService } from '~core/services/ui/dialog.service'
import { SnackbarService } from '~core/services/ui/snackbar.service'
import { environment } from '~env'

@Component({
  selector: 'app-signin',
  templateUrl: './signin.view.html',
  standalone: true,
  imports: [
    TranslocoModule,
    LogoComponent,
    TextComponent,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    RouterLink,
    DialogComponent,
  ],
})
export class SigninView implements OnInit, OnDestroy {
  dialogRef: MatDialogRef<any>
  icon: SafeHtml
  invalidCredentials: boolean
  invalidFBLoginDialogRef: MatDialogRef<any>
  @ViewChild('invalidFacebookLogin') invalidFBLoginDialogTemplate: TemplateRef<any>
  isLoading = false
  returnUrl: string
  showPassword: boolean
  signInForm: FormGroup
  statusMessage: string
  private facebookLoginSubscription = Subscription.EMPTY
  private facebookRedirectUri: string
  protected Icons = IconOutlined

  constructor(
    private facebookService: FacebookApiService,
    private router: Router,
    private dialogService: DialogService,
    private translateService: TranslocoService,
    private snackbarService: SnackbarService,
    private userService: UserService,
    private route: ActivatedRoute,
  ) {}

  ngOnDestroy() {
    this.facebookLoginSubscription.unsubscribe()
  }

  ngOnInit() {
    this.facebookRedirectUri = window.location.origin + window.location.pathname
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/'
    this.signInForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, Validators.required),
    })
    this.showPassword = false
    this.subscribeToFacebookLogin()
  }

  onClickFbLogin() {
    this.isLoading = true
    this.facebookService.login(this.facebookRedirectUri, null)
  }

  onClickGoToRegister() {
    this.router.navigate(['/register']).then(() => this.invalidFBLoginDialogRef.close())
  }

  onShowPassword() {
    this.showPassword = !this.showPassword
  }

  onSignIn(dialogFb: TemplateRef<any>) {
    if (this.signInForm.invalid) {
      this.signInForm.markAllAsTouched()
      return
    }
    this.invalidCredentials = false
    this.statusMessage = 'Signing in...'
    if (this.signInForm.value.email === 'test@sbam.io' && environment.production) {
      this.statusMessage = 'Please log in from <a href="https://staging.app.sbam.io">https://staging.app.sbam.io</a>'
      this.dialogRef = this.dialogService.open(dialogFb)
      return
    }
    this.userService.attemptAuth(this.signInForm.value).subscribe({
      next: () => this.router.navigateByUrl(this.returnUrl),
      error: (error) => {
        let message = ''
        switch (error.status) {
          case 401: // invalid credentials
            message = this.translateService.translate('signin.invalidCredentials')
            break
          case 503: // maintenance
            return this.router.navigateByUrl('/maintenance')
          default:
            message = this.translateService.translate('alerts.somethingGoesWrong')
            break
        }
        this.statusMessage = message
        this.invalidCredentials = true
      },
    })
  }

  removeQueryParams() {
    this.router.navigate([this.router.url.split('?')[0]])
  }

  subscribeToFacebookLogin() {
    this.facebookLoginSubscription = this.route.queryParamMap
      .pipe(
        switchMap((queryParams: ParamMap) => {
          if (!queryParams.has('granted_scopes') || !queryParams.has('code')) {
            this.removeQueryParams()
            return of(false)
          }
          this.isLoading = true
          const grantedScopes = queryParams.get('granted_scopes').split(',')
          this.facebookService.checkScopes(['email'], grantedScopes)
          return this.userService.facebookLogin(queryParams.get('code'), this.facebookRedirectUri)
        }),
        filter((isValid) => !!isValid),
      )
      .subscribe({
        next: () => {
          this.removeQueryParams()
          this.router.navigate([this.returnUrl])
        },
        error: (error) => {
          this.removeQueryParams()
          if (error instanceof Error) {
            this.snackbarService.error(error.message)
          }
          let message = ''
          switch (error.status) {
            case 401: // invalid credentials
              this.invalidFBLoginDialogRef = this.dialogService.open(this.invalidFBLoginDialogTemplate)
              break
            case 503: // maintenance
              return this.router.navigate(['maintenance'])
            default:
              message = 'Ops! Qualcosa è andato storto!'
              break
          }
          this.isLoading = false
          this.statusMessage = message
          this.invalidCredentials = true
        },
      })
  }
}
