import { Location, AsyncPipe } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker'
import { getBrowserLang, TranslocoService } from '@ngneat/transloco'
import { Store } from '@ngrx/store'
import { AngularQueryDevtools } from '@tanstack/angular-query-devtools-experimental'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { setTheme } from 'ngx-bootstrap/utils'
import { Observable, Subscription } from 'rxjs'
import { delay, distinctUntilChanged, filter, map } from 'rxjs/operators'
import { ChannelFetchedComponent } from 'src/app/common/components/channel-fetched/channel-fetched.component'
import { UpdateDialogComponent } from 'src/app/common/components/update-dialog/update-dialog.component'

import { UserService } from '~core/services'
import { DialogService } from '~core/services/ui/dialog.service'
import { ScreenService, ScreenSize } from '~core/services/ui/screen.service'
import { SnackbarService } from '~core/services/ui/snackbar.service'
import { WebsocketService } from '~core/services/websocket.service'
import { selectIsLoading } from '~core/store/core.selectors'
import { setScreenSize } from '~core/store/ui/ui.actions'
import { selectIsScreenSizeGte } from '~core/store/ui/ui.selectors'
import logger from '~core/utils/logger'
import { environment } from '~env'
import { User } from '~features/user/models/user.model'

import { LoaderComponent } from './common/components/loader/loader.component'

dayjs.extend(isBetween)

@Component({
  selector: 'app-root',
  template: `
    @if (isLoading$ | async) {
      <sb-loader overlay />
    }
    <router-outlet />
    @if (!environment.production) {
      <angular-query-devtools />
    }
  `,
  standalone: true,
  imports: [LoaderComponent, RouterOutlet, AsyncPipe, AngularQueryDevtools],
})
export class AppComponent implements OnInit, OnDestroy {
  isLoading$: Observable<boolean>
  user: User
  protected environment = environment

  private channelFetchedEvents = [
    'FacebookPageFetched',
    'InstagramAccountFetched',
    'FacebookAdAccountFetched',
    'LinkedinOrganizationFetched',
  ]
  private subscriptions: Subscription[] = []

  constructor(
    private store: Store,
    private location: Location,
    private swUpdate: SwUpdate,
    private userService: UserService,
    private dialogService: DialogService,
    private snackbar: SnackbarService,
    private websocketService: WebsocketService,
    private translateService: TranslocoService,
    private screenService: ScreenService,
  ) {
    setTheme('bs4')
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe())
  }

  ngOnInit(): void {
    this.handleScreenSize()
    const locale = this.getGuestLanguage()
    this.isLoading$ = this.store.select(selectIsLoading).pipe(delay(0), distinctUntilChanged())
    this.translateService.setActiveLang(locale)
    if (!this.isPublicUrl()) {
      this.userService.populate()
      this.listenToChannelFetch()
    }
    this.handleUpdates()
  }

  private changeCustomerlyVisibility(visible: boolean) {
    // Check if customerly exists
    if (typeof (<any>window).customerly !== 'undefined') {
      ;(<any>window).customerly.update({ visible })
    }
  }

  private getGuestLanguage() {
    let locale = getBrowserLang()
    if (environment.availableLanguages.indexOf(locale) === -1) {
      locale = 'en'
    }

    return locale
  }

  private handleScreenSize() {
    this.subscriptions.push(
      this.screenService.size.subscribe((payload) => {
        this.store.dispatch(setScreenSize({ payload }))
      }),
      this.store.select(selectIsScreenSizeGte(ScreenSize.Medium)).subscribe((isGteMd) => {
        this.changeCustomerlyVisibility(isGteMd ? true : false)
      }),
    )
  }

  private handleUpdates() {
    if (!this.swUpdate.isEnabled) {
      logger.info('SwUpdate not enabled')
    } else {
      this.swUpdate.versionUpdates
        .pipe(
          filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
          map((evt) => ({
            type: 'UPDATE_AVAILABLE',
            current: evt.currentVersion,
            available: evt.latestVersion,
          })),
        )
        .subscribe((event) => {
          if (environment.forceUpdate) {
            this.swUpdate.activateUpdate().then(() => document.location.reload())
          } else {
            logger.info('Update available: current version is', event.current, 'available version is', event.available)
            this.dialogService.open(UpdateDialogComponent, { width: '450px' })
          }
        })
    }
  }

  private isPublicUrl() {
    return this.location.path().startsWith('/public/')
  }

  private listenToChannelFetch() {
    let dialogService = null
    this.channelFetchedEvents.forEach((event) =>
      this.subscriptions.push(
        this.websocketService.listen(event).subscribe((channel) => {
          if (!dialogService) {
            dialogService = this.dialogService.open(ChannelFetchedComponent, { data: { channels: [channel] } })
          } else {
            dialogService.content.channels.push(channel)
          }
          this.userService.onRefreshChannel(channel)
        }),
      ),
    )
  }
}
