import { abs, atan, atan2, cos, epsilon, halfPi, log, pi, pow, sign, sin, sqrt, tan } from "../math.js";
import { conicProjection } from "./conic.js";
import { mercatorRaw } from "./mercator.js";
function tany(y) {
  return tan((halfPi + y) / 2);
}
export function conicConformalRaw(y0, y1) {
  var cy0 = cos(y0),
    n = y0 === y1 ? sin(y0) : log(cy0 / cos(y1)) / log(tany(y1) / tany(y0)),
    f = cy0 * pow(tany(y0), n) / n;
  if (!n) return mercatorRaw;
  function project(x, y) {
    if (f > 0) {
      if (y < -halfPi + epsilon) y = -halfPi + epsilon;
    } else {
      if (y > halfPi - epsilon) y = halfPi - epsilon;
    }
    var r = f / pow(tany(y), n);
    return [r * sin(n * x), f - r * cos(n * x)];
  }
  project.invert = function (x, y) {
    var fy = f - y,
      r = sign(n) * sqrt(x * x + fy * fy),
      l = atan2(x, abs(fy)) * sign(fy);
    if (fy * n < 0) l -= pi * sign(x) * sign(fy);
    return [l / n, 2 * atan(pow(f / r, 1 / n)) - halfPi];
  };
  return project;
}
export default function () {
  return conicProjection(conicConformalRaw).scale(109.5).parallels([30, 30]);
}