import { HttpErrorResponse, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http'
import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { provideAnimations } from '@angular/platform-browser/animations'
import { provideRouter, withRouterConfig } from '@angular/router'
import { provideServiceWorker } from '@angular/service-worker'
import '@stripe/stripe-js'
import { QueryClient, provideAngularQuery } from '@tanstack/angular-query-experimental'
import { ChoroplethController, ColorScale, GeoFeature, ProjectionScale } from 'chartjs-chart-geo'
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix'
import { WordCloudController, WordElement } from 'chartjs-chart-wordcloud'
import AnnotationPlugin from 'chartjs-plugin-annotation'
import TrendlinePlugin from 'chartjs-plugin-trendline'
import { NgChartsModule } from 'ng2-charts'
import { SortableModule } from 'ngx-bootstrap/sortable'
import { IconsModule } from 'src/app/common/icons/icons.module'
import { translocoProviders } from 'src/app/transloco.config'

import { commonConfig } from '~common/common.config'
import { AppConfig as Configurations } from '~core/config/config'
import { provideCore } from '~core/core.config'
import { acceptLanguageInterceptor } from '~core/interceptors/accept-language.interceptor'
import { jwtInterceptor } from '~core/interceptors/jwt.interceptor'
import logger from '~core/utils/logger'
import { integrationsConfig } from '~features/integrations/integrations.config'

import { routes } from './app.routes'
import { provideMaterial } from './material.config'
import { provideAppStore } from './store.config'

const BOOTSTRAP_MODULES = [SortableModule.forRoot()]

export const appConfig: ApplicationConfig = {
  providers: [
    ...provideMaterial(),
    importProvidersFrom(
      BrowserModule,
      ...BOOTSTRAP_MODULES,
      IconsModule,
      NgChartsModule.forRoot({
        plugins: [
          AnnotationPlugin,
          TrendlinePlugin,
          MatrixController,
          MatrixElement,
          ChoroplethController,
          GeoFeature,
          ColorScale,
          ProjectionScale,
          WordCloudController,
          WordElement,
        ],
      }),
    ),
    ...provideCore(),
    ...provideAppStore(),
    ...translocoProviders,
    ...integrationsConfig,
    ...commonConfig,
    provideRouter(routes, withRouterConfig(Configurations.ROUTER)),
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([acceptLanguageInterceptor, jwtInterceptor])),
    provideAngularQuery(
      new QueryClient({
        defaultOptions: {
          queries: {
            throwOnError: false,
            retry: (failureCount, error) => {
              // do not retry on 401 or 403
              if (error instanceof HttpErrorResponse && [401, 403].includes(error.status)) {
                return false
              }
              return failureCount < 3
            },
          },
        },
      }),
    ),
    provideAnimations(),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
}
