import { rgb } from "d3-color";
var c = rgb(),
  pi_1_3 = Math.PI / 3,
  pi_2_3 = Math.PI * 2 / 3;
export default function (t) {
  var x;
  t = (0.5 - t) * Math.PI;
  c.r = 255 * (x = Math.sin(t)) * x;
  c.g = 255 * (x = Math.sin(t + pi_1_3)) * x;
  c.b = 255 * (x = Math.sin(t + pi_2_3)) * x;
  return c + "";
}