import { NgModule } from '@angular/core'
import { MatIconModule, MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'

import { Icon, IconOutlined } from '.'

@NgModule({
  imports: [MatIconModule],
  exports: [MatIconModule],
  providers: [MatIconRegistry],
})
export class IconsModule {
  constructor(
    private domSanitizer: DomSanitizer,
    public matIconRegistry: MatIconRegistry,
  ) {
    this.registerIcons()
  }

  public registerIcons(): void {
    this.loadIcons(Object.values(Icon), '/assets/icons')
    this.loadIcons(Object.values(IconOutlined), '/assets/icons')
  }

  private loadIcons(iconKeys: string[], iconUrl: string): void {
    // TODO: make outlined icons default and use fill as namespace for filled version of icon
    iconKeys.forEach((key) => {
      if (key.includes(':')) {
        const [namespace, iconKey] = key.split(':')
        this.matIconRegistry.addSvgIconInNamespace(
          namespace,
          iconKey,
          this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${iconKey}.svg`),
        )
      } else {
        this.matIconRegistry.addSvgIcon(
          key,
          this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${key}-fill.svg`),
        )
      }
    })
  }
}
