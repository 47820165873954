import { Routes } from '@angular/router'

import { authenticationGuard } from '~core/guards/authentication.guard'
import { ADMIN_ROUTES } from '~features/admin/admin.routes'
import { AUTH_ROUTES } from '~features/auth/auth-routes'
import { FacebookDeletionComponent } from '~features/facebook-deletion/facebook-deletion.component'
import { IgAccountHelpComponent } from '~features/ig-account-help/ig-account-help.component'
import { INTEGRATIONS_ROUTES } from '~features/integrations/integrations.routes'
import { MaintenanceComponent } from '~features/maintenance/maintenance.component'
import { NotFoundComponent } from '~features/not-found/not-found.component'
import { projectsConfig } from '~features/projects/projects.config'

export const routes: Routes = [
  { path: '', redirectTo: '/projects', pathMatch: 'full' },
  { path: 'troubleshooting', component: IgAccountHelpComponent, canActivate: [authenticationGuard] },
  { path: 'maintenance', component: MaintenanceComponent },
  { path: 'facebook-deletion/:id', component: FacebookDeletionComponent },
  { path: 'public', loadChildren: () => import('~features/public/public.routes').then((m) => m.PUBLIC_ROUTES) },
  { path: 'user', loadChildren: () => import('~features/user/user.routes').then((m) => m.USER_ROUTES) },
  {
    path: 'projects',
    providers: projectsConfig,
    loadChildren: () => import('~features/projects/projects.routes').then((m) => m.PROJECTS_ROUTES),
  },
  ...INTEGRATIONS_ROUTES,
  ...ADMIN_ROUTES,
  ...AUTH_ROUTES,
  { path: '404', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent },
]
