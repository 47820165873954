<ng-container *transloco="let t">
  <div class="bg-surface">
    <div class="container">
      <div class="flex h-screen flex-wrap items-center p-5">
        <div class="mx-auto mb-5 mt-9 w-full md:mb-0 md:mt-0 md:w-1/2 md:pr-9">
          @if (!isMarketingEspressoPromo) {
          <sb-info-section></sb-info-section>
          } @if (isMarketingEspressoPromo) {
          <sb-info-section-promo></sb-info-section-promo>
          }
        </div>
        <div class="w-full md:w-1/2 lg:pl-5">
          <sb-card>
            <ng-template #content>
              <sb-text variant="title" align="center" class="mb-4 mt-2">{{ t('signup.title') }}</sb-text>
              <form (ngSubmit)="onSignUp()" [formGroup]="form" class="grid grid-cols-2 gap-4">
                <sb-button
                  variant="outlined"
                  [icon]="Icons.Facebook"
                  (sbClick)="onSignupViaFacebook()"
                  [disabled]="isLoading"
                  fill
                  type="button"
                  class="col-span-2 my-7 lg:my-9"
                >
                  {{ t('signup.facebookLogin') }}
                </sb-button>
                <mat-form-field class="col-span-2 md:col-span-1">
                  <mat-label>{{ t('signup.form.firstName') }}</mat-label>
                  <input matInput [placeholder]="t('signup.form.firstName')" formControlName="firstName" type="text" />
                  @if (!form.get('firstName').valid && form.get('firstName').touched) {
                  <mat-error>
                    {{ "validation.required" | transloco: { attribute: "signup.form.firstName" | transloco } }}
                  </mat-error>
                  }
                </mat-form-field>
                <mat-form-field class="col-span-2 md:col-span-1">
                  <mat-label>{{ t('signup.form.lastName') }}</mat-label>
                  <input matInput [placeholder]="t('signup.form.lastName')" formControlName="lastName" type="text" />
                  @if (!form.get('lastName').valid && form.get('lastName').touched) {
                  <mat-error>
                    {{ "validation.required" | transloco: { attribute: "signup.form.lastName" | transloco } }}
                  </mat-error>
                  }
                </mat-form-field>
                <mat-form-field class="col-span-2">
                  <mat-label>{{ t('signup.form.email') }}</mat-label>
                  <input matInput [placeholder]="t('signup.form.email')" formControlName="email" type="email" />
                  @if (!form.get('email').valid && form.get('email').touched) {
                  <mat-error>
                    @if (form.get('email').errors['required']) { {{ "validation.required" | transloco: { attribute:
                    "signup.form.email" | transloco } }} } @if (form.get('email').errors['email']) { {{
                    "validation.regex" | transloco: { attribute: "signup.form.email" | transloco } }} }
                  </mat-error>
                  }
                </mat-form-field>
                <mat-form-field class="col-span-2">
                  <mat-label>{{ t("signup.form.password") }}</mat-label>
                  <!-- Input group -->
                  <input
                    matInput
                    [placeholder]="t('form.passwordPlaceholder')"
                    [type]="showPassword ? 'text' : 'password'"
                    formControlName="password"
                  />
                  <sb-button
                    variant="icon"
                    matSuffix
                    [icon]="showPassword ? Icons.VisibilityOff : Icons.Visibility"
                    (sbClick)="showPassword = !showPassword"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="!showPassword"
                  />
                  @if (!form.get('password').valid && form.get('password').touched) {
                  <mat-error>
                    @if (form.get('password').errors['required']) { {{ "validation.required" | transloco: { attribute:
                    "reset.form.password" | transloco } }} } @if ( form.get('password').errors['pattern'] ||
                    form.get('password').errors['minLength'] ) { {{ "validation.regex" | transloco: { attribute:
                    "reset.form.password" | transloco } }} }
                  </mat-error>
                  }
                  <mat-hint>{{ t('auth.info.PasswordRequirements') }}</mat-hint>
                </mat-form-field>
                <div class="col-span-2 mt-6 grid">
                  <sb-text align="center" variant="label" class="mb-1">
                    {{ t('signup.policy.pre') }}
                    <a href="//sbam.io/terms" target="_blank">{{ t('signup.policy.terms') }}</a>
                    {{ t('signup.policy.and') }}
                    <a href="//sbam.io/privacy" target="_blank">{{ t('signup.policy.privacy') }}</a>
                  </sb-text>
                  <sb-button fill [disabled]="isLoading" id="register" type="submit">
                    {{ t('signup.form.button') }}
                  </sb-button>
                  <sb-text variant="label" align="center" class="mt-1">{{ t('signup.subtitle') | uppercase }}</sb-text>
                  <sb-text variant="label" align="center" class="mt-5" muted>
                    {{ t('signup.alreadyHaveAnAccount') }}
                    <a routerLink="/login">{{ t('signup.signin') }}</a>
                  </sb-text>
                </div>
              </form>
            </ng-template>
          </sb-card>
        </div>
      </div>
    </div>
  </div>
</ng-container>
